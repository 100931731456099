import request from '@/http/request';

//工单统计-过程统计列表
//服务器退役
export function statisticsList (data) {
  return request({
    url: `/tickets/retire/statistics`,
    method: 'POST',
    data
  });
}

//TPC异常
export function tpcList (data) {
  return request({
    url: `/tickets/tpc/stats`,
    method: 'POST',
    data
  });
}

//网络故障
export function networkList (params) {
  return request({
    url: `/tickets/network/ticketTotal`,
    method: 'GET',
    params
  });
}
//服务器搬迁 
export function moveList (data) {
  return request({
    url: `/tickets/move/statistics`,
    method: 'POST',
    data
  });
}

